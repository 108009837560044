import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import FingoLink from '../links/FingoLink';
import { InvoiceProfileDialog } from '../dialogs';
import { useBooleanState } from '../../hooks';
import { InvoiceType } from '../../propTypes';
import InvoiceCessionsTooltip from './InvoiceCessionsTooltip';

const FolioWithProfileAndAlerts = ({ invoice, AlertsArray }) => {
  const [open, toggleBoolean, setTrue] = useBooleanState(false);
  return (
    <Stack justifyContent="center" display="flex">
      <InvoiceCessionsTooltip invoice={invoice}>
        <FingoLink
          textAlign="center"
          variant="body2"
          underline="hover"
          onClick={setTrue}
        >
          {invoice.folio}
        </FingoLink>
      </InvoiceCessionsTooltip>
      {AlertsArray && <AlertsArray invoice={invoice} />}
      {open && <InvoiceProfileDialog invoiceId={invoice.id} open={open} setOpen={toggleBoolean} />}
    </Stack>
  );
};

FolioWithProfileAndAlerts.propTypes = {
  invoice: InvoiceType.isRequired,
  AlertsArray: PropTypes.element,
};

FolioWithProfileAndAlerts.defaultProps = {
  AlertsArray: null,
};

export default FolioWithProfileAndAlerts;
