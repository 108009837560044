import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useStatusStyles } from '../../helpers/restrictions';

const RiskStatusCell = ({ status }) => {
  const styles = useStatusStyles();
  const style = styles[status] || styles.DEFAULT;

  return (
    <Box
      sx={{
        ...style,
        borderRadius: '6px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.2rem 0.5rem',
        width: 'auto',
      }}
    >
      <Typography variant="body2" sx={{ fontSize: '0.55rem' }}>
        {status}
      </Typography>
    </Box>
  );
};

RiskStatusCell.propTypes = {
  status: PropTypes.string.isRequired,
};

export default RiskStatusCell;
