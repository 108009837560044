import { Typography, Tooltip, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const RestrictionDetailCell = ({ row }) => {
  const { endorser, triggeringInvoices, restriction } = row;

  if (!endorser && (!triggeringInvoices || triggeringInvoices.edges.length === 0)) {
    return null;
  }

  const shouldAddBlankLine = triggeringInvoices?.edges.length >= 4;

  if (endorser) {
    return (
      <Tooltip title="Aval del contrato">
        <Typography sx={{ fontSize: '0.65rem' }}>
          {endorser.relatedPerson?.name}
        </Typography>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={restriction.includes('PURCHASEORDER') ? 'Números de OC' : 'Folios'}>
      <Stack>
        {shouldAddBlankLine && <Typography sx={{ fontSize: '0.65rem' }}>&nbsp;</Typography>}
        {triggeringInvoices?.edges.map((edge) => (
          <Typography sx={{ fontSize: '0.65rem' }} key={edge.node.id}>
            {edge.node.folio}
          </Typography>
        ))}
      </Stack>
    </Tooltip>
  );
};

RestrictionDetailCell.propTypes = {
  row: PropTypes.shape({
    restriction: PropTypes.string.isRequired,
    endorser: PropTypes.shape({
      relatedPerson: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    triggeringInvoices: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          folio: PropTypes.string.isRequired,
        }),
      ),
    }),
  }).isRequired,
};

export default RestrictionDetailCell;
