import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Typography } from '@mui/material';
import { DocumentFinanceStateType } from '../../propTypes';

const colors = {
  finished: '#237A39',
  rejected: '#E40F0F',
  blocked: '#F8B147',
  default: '#D4D4D4',
};

const validDictionary = (invoice) => ({
  label: invoice.hasExecutiveMerit ? 'Con mérito' : `${invoice.daysToExecutiveMerit} días para el mérito ejecutivo`,
  color: invoice.hasExecutiveMerit ? colors.finished : colors.default,
  last: 'sii',
});

const siiStatusMaster = (invoice) => ({
  PROCESSING: { last: 'sii', color: colors.default, label: 'En proceso' },
  REJECTED_BY_SII: { last: 'sii', color: colors.rejected, label: 'Rechazado por SII' },
  REJECTED_RECEIVER: { last: 'sii', color: colors.rejected, label: 'Rechazado por Receptor' },
  REJECTED_CONTENT: { last: 'sii', color: colors.rejected, label: 'Con reclamos al contenido' },
  REJECTED_TOTAL: { last: 'sii', color: colors.rejected, label: 'Con reclamos por falta total' },
  REJECTED_PARTIAL: {
    last: 'sii',
    color: colors.rejected,
    label: 'Con reclamos por falta parcial',
  },
  VALID: validDictionary(invoice),
  RECEPTION_ACKNOWLEDGED: { last: 'sii', color: colors.finished, label: 'Con acuse de recibo' },
  PARTIAL_CLAIM: {
    last: 'sii',
    color: colors.rejected,
    label: 'Con rechazo por falta parcial de mercaderías',
  },
  TOTAL_CLAIM: {
    last: 'sii',
    color: colors.rejected,
    label: 'Con rechazo por falta total de mercaderías',
  },
  ALREADY_PAYED: { last: 'sii', color: colors.finished, label: 'Emitida al contado' },
  INVALID_STATUS: validDictionary(invoice),
  UNKNOWN_STATUS: validDictionary(invoice),
});

const showComment = (invoice) => {
  const { siiStatus, uniqueDocumentFinanceState } = invoice;
  const lastApiState = uniqueDocumentFinanceState?.reduce((prev, current) => {
    if (moment(current.eventTime) > moment(prev.eventTime)) return current;

    return prev;
  }, {});

  if (lastApiState.rejectionCode?.reason) return lastApiState.rejectionCode.reason;
  if (lastApiState.status?.code === '7') {
    return `${lastApiState.status.status}\n${lastApiState.paymentDate?.format('DD-MM-YYYY') || ''}`;
  }
  if (lastApiState.status) return lastApiState.status.status;
  return siiStatusMaster(invoice)[siiStatus].label;
};

const SiiStatusTextCell = ({ invoice, ...props }) => (
  <Typography {...props}>{showComment(invoice)}</Typography>
);

SiiStatusTextCell.propTypes = {
  invoice: PropTypes.shape({
    receptionDate: PropTypes.instanceOf(moment).isRequired,
    siiStatus: PropTypes.string.isRequired,
    uniqueDocumentFinanceState: PropTypes.arrayOf(DocumentFinanceStateType).isRequired,
    hasExecutiveMerit: PropTypes.bool.isRequired,
    daysToExecutiveMerit: PropTypes.number.isRequired,
  }).isRequired,
};

export default SiiStatusTextCell;
