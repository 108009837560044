import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@mui/material';
import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { green, red } from '@mui/material/colors';

const TwoActionsButtonsCell = ({ onAccept, onReject, ...props }) => (
  <Grid container {...props}>
    <IconButton onClick={onAccept}>
      <CheckCircle sx={{ color: green[700] }} />
    </IconButton>
    <IconButton onClick={onReject}>
      <Cancel sx={{ color: red[700] }} />
    </IconButton>
  </Grid>
);

TwoActionsButtonsCell.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export default TwoActionsButtonsCell;
