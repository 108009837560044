import { Card, CardContent, CardHeader } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const GreyCard = ({ title, subheader, content }) => (
  <Card
    sx={{
      bgcolor: 'background.dark',
      border: 0,
      borderRadius: 4,
    }}
    variant="outlined"
  >
    <CardHeader title={title} subheader={subheader} />
    <CardContent>
      {content}
    </CardContent>
  </Card>
);

GreyCard.propTypes = {
  title: PropTypes.node.isRequired,
  subheader: PropTypes.node,
  content: PropTypes.node.isRequired,
};

GreyCard.defaultProps = {
  subheader: null,
};

export default GreyCard;
